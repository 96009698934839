function animations() {

    // Reveal 
    var reveals = document.querySelectorAll(".reveal");
  
    for (var i = 0; i < reveals.length; i++) {
        var windowHeight = window.innerHeight;
        var elementTop = reveals[i].getBoundingClientRect().top;
        var elementVisible = 150;
  
        if (elementTop < windowHeight - elementVisible) {
            reveals[i].classList.add("active");
        } else {
            reveals[i].classList.remove("active");
        }
    }

    // Staggered Reveal 
    var staggeredReveals = document.querySelectorAll(".staggered-reveal");
  
    for (var i = 0; i < staggeredReveals.length; i++) {
        staggeredReveal(i); 
    }

    function staggeredReveal(i) {
        setTimeout(function() { 
            var windowHeight = window.innerHeight;
            var elementTop = staggeredReveals[i].getBoundingClientRect().top;
            var elementVisible = 150;
    
            if (elementTop < windowHeight - elementVisible) {
                staggeredReveals[i].classList.add("active");
            } else {
                staggeredReveals[i].classList.remove("active");
            }
        }, 300 * i); 
    }

    // Move up
    var moveups = document.querySelectorAll(".moveup");
  
    for (var i = 0; i < moveups.length; i++) {
        var windowHeight = window.innerHeight;
        var elementTop = moveups[i].getBoundingClientRect().top;
        var elementVisible = 150;
  
        if (elementTop < windowHeight - elementVisible) {
            moveups[i].classList.add("active");
        } else {
            moveups[i].classList.remove("active");
        }
    }

    // Move up after scroll down (and reset on scroll up)
    var moveupAfterscrolls = document.querySelectorAll(".moveup-afterscroll");
  
    for (var i = 0; i < moveupAfterscrolls.length; i++) {
        var windowHeight = window.innerHeight;
        var elementTop = moveupAfterscrolls[i].getBoundingClientRect().top;
        var elementVisible = 150;
  
        if (elementTop < windowHeight - elementVisible) {
            moveupAfterscrolls[i].classList.add("active");
        } else {
            moveupAfterscrolls[i].classList.remove("active");
        }

        if (elementTop < 50) {
            moveupAfterscrolls[i].classList.remove("active");
        }
        
    }

}

  
window.addEventListener("scroll", animations);