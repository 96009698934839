import "./modules/lazy-load-images";
import "./modules/content-carousel";
import "./modules/smooth-anchor-scrolling";
import "./modules/in-page-navigation";
import "./modules/stockists-grid";
import "./modules/site-header-menu";
import "./modules/animation";
import "./modules/customer-stories-carousel";
import "./modules/shopper-reviews-carousel";
import "./modules/activities-carousel";
import "./modules/50-50-content-plus-carousel";

// Vendor Scripts
import { Tab, Collapse } from 'bootstrap';